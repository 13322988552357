/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {

        SPOTLIGHT.on("cep.loaded", function () {
          var triggersCEP = {
              "register": "register",
              "newsletter": "registerNewsletter",
              "signin": "signIn"
            },
            hashCEP = window.location.hash.split("#").pop().toLowerCase();

          if (Object.keys(triggersCEP).indexOf(hashCEP) !== -1 && typeof SPOTLIGHT !== 'undefined') {
            SPOTLIGHT[triggersCEP[hashCEP]]();
          }
        });

        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        twttr.ready(function() {
          twttr.widgets.createTimeline(
            {
              sourceType: 'profile',
              screenName: 'DewarsAberfeldy'
            },
            document.getElementById('js-social-feed--twitter'),
            {
              chrome: "transparent noheader noscrollbar nofooter",
              related: 'twitterdev,twitterapi'
            }).then(function (el) {
                var styles = '.TweetAuthor a,.TweetAuthor span{color:#cea04a}.timeline-Tweet-text{margin:10px 0 20px;color:#cea04a;line-height:1.4!important;font-size:16px!important}.timeline-Tweet-timestamp{margin:0;font-size:75%!important;color:hsla(0,0%,100%,.8)}.timeline-TweetList-tweet{margin:10px 0!important;border:none;border-radius:0;background:#000;box-shadow:none;border-bottom:1px solid #cea04a;font-family:ag-old-face,Helvetica Neue,Helvetica,Arial,sans-serif!important;font-size:1rem!important;font-weight:400!important;font-style:normal!important;font-stretch:normal!important;line-height:1.5!important}.timeline-TweetList-tweet a{font-weight:700;color:#cea04a;text-decoration:underline}.timeline-TweetList-tweet a:hover{color:hsla(0,0%,100%,.8)}.timeline-Tweet-retweetCredit{font-size:75%;margin:10px 0 0!important;color:hsla(0,0%,100%,.8)}.timeline-LoadMore-prompt{color:#cea04a;border-color:#cea04a}.timeline-LoadMore-prompt:hover,.timeline-ShowMoreButton:focus,.timeline-ShowMoreButton:hover{background-color:#cea04a}.TwitterCard-container{color:#cea04a!important}.TwitterCard-container--clickable:hover{color:hsla(0,0%,100%,.8)!important;background-color:#cea04a!important}.TwitterCard-container--clickable:hover .SummaryCard-destination{text-decoration:underline}.TwitterCard-container--clickable .SummaryCard-destination{color:hsla(0,0%,100%,.8)!important}';
                $('#twitter-widget-0').contents().find('head').append("<style type=\"text/css\">" + styles + "</styles");
            });
        });
        // // JavaScript to be fired on the home page
        $('.js-social-feed').mCustomScrollbar({
          axis: 'y',
          mouseWheel:{ scrollAmount: 250 }
        });
        
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  // Load social share icons
  $('.share__buttons .cta').on("click", function (e) {
    e.preventDefault();
    if ( !$(this).hasClass('open')) {
      $(e.currentTarget).addClass('open');
      $(e.currentTarget).parent().addClass('open');
      $(e.currentTarget).parent().parent().parent().find('.share__buttons').addClass('open');
    } else {
      $(e.currentTarget).removeClass('open');
      $(e.currentTarget).parent().removeClass('open');
      $(e.currentTarget).parent().parent().parent().find('.share__buttons').removeClass('open');
    }
  });

})(jQuery); // Fully reference jQuery after this point.
